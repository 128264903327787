<template>
  <div v-loading="loading">
    <el-card shadow="never" :body-style="{ padding: '20px' }">
      <div slot="header">
        <span class="iconfont icon-MallCategory">&nbsp;商品分类</span>
      </div>
      <div class="card_header_flex">
        <div>
          <el-button type="primary" size="small" @click="dialogAddMallCategory=true">新增商品分类</el-button>
          <el-button
            size="small"
            plain
            @click="onSubmitBatchDel"
            v-show="dataLineSelections.length>0"
          >批量删除</el-button>
        </div>
      </div>
      <el-table
        ref="table"
        :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)"
        size="mini"
        @selection-change="selectionLineChangeHandle"
        row-key="id"
      >
        <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
        <el-table-column prop="sort" label="排序" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.sort" size="mini" @blur="blurMallCategorySort(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="cnName" label="中文名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isSelectedName"
              size="mini"
              v-model="scope.row.cnName"
              @focus="focusEvent(scope.row)"
              @blur="blurEvent(scope.row)"
              v-focus
            ></el-input>
            <span v-else>
              {{ scope.row.cnName }}
              <i
                class="el-icon-edit-outline"
                style="color:#5e72f9;font-size:15px;margin-left:10px;cursor:pointer;vertical-align:middle"
                @click="cellClick(scope.row)"
              ></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="enName" label="英文名称"></el-table-column>
        <el-table-column prop="commodityCount" label="商品数量"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="onSubmitDelMallCategory(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page_right">
        <el-pagination
          background
          layout="total,prev, pager, next ,jumper"
          :total="list.length"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 添加商品分类 -->
    <el-dialog
      :visible.sync="dialogAddMallCategory"
      :close-on-click-modal="false"
      width="25%"
      @closed="closeDialogAddMallCategory"
    >
      <span slot="title">新增商品分类</span>
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="名称"
          prop="cnName"
          :rules="{ required: true, message: '请输入名称', trigger: 'blur' }"
        >
          <el-input v-model="form.cnName" placeholder="请输入名称" maxlength="11" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddMallCategory=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddCategory">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Aes from "../../../utils/aes";
import {
  getMallCategoryApi,
  addMallCategoryApi,
  editMallCategoryApi,
  delMallCategoryApi
} from "../../../api/data";
export default {
  data() {
    return {
      loading:true,
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      list: [],
      form: {
        cnName: "",
        groupId: ""
      },
      dataLineSelections: [], //表格选中集合
      dialogAddMallCategory: false //添加分类窗口
    };
  },
  computed: {
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  //自定义指令对文本框添加焦点
  directives: {
    focus: {
      inserted: function(el) {
        el.querySelector("input").focus();
      }
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    //初始化商品分类
    initLoad() {
      getMallCategoryApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.loading=false;
        this.list = res.data.map(item => {
          return { ...item, isSelectedName: false };
        });
      });
    },
    //添加商品分类
    onSubmitAddCategory() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.groupId = this.placesInfo.groupId;
          addMallCategoryApi(this.form).then(res => {
            this.$message.success(res.msg);
            this.initLoad();
            this.dialogAddMallCategory = false;
          });
        } else {
          return false;
        }
      });
    },
    //添加窗口关闭事件
    closeDialogAddMallCategory() {
      this.$refs["form"].resetFields();
    },
    //删除分类
    onSubmitDelMallCategory(row) {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        const mallCategoryArray = [];
        mallCategoryArray.push(row.id);
        delMallCategoryApi({ ids: mallCategoryArray }).then(res => {
          this.$message.success(res.msg);
          this.initLoad();
        });
      });
    },
    //批量删除
    onSubmitBatchDel() {
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delMallCategoryApi({ ids: this.dataLineSelections }).then(res => {
          this.$message.success(res.msg);
          this.initLoad();
          this.$refs.table.clearSelection();
        });
      });
    },
    //修改排序
    blurMallCategorySort(row) {
      editMallCategoryApi(row).then(res => {
        this.$message.success(res.msg);
        this.initLoad();
      });
    },
    //表格多选框选中事件
    selectionLineChangeHandle(val) {
      const selectionList = [];
      for (let i = 0; i < val.length; i++) {
        selectionList.push(val[i].id);
      }
      this.dataLineSelections = selectionList;
    },
    //编辑房间号事件
    cellClick(row) {
      row.isSelectedName = !row.isSelectedName;
    },
    //触发焦点记录用户聚焦前的值
    focusEvent(row) {
      row.oldCnName = row.cnName;
    },
    //失去焦点事件
    blurEvent(row) {
      row.isSelectedName = !row.isSelectedName;
      //判断前后两次的值是否一样
      if (row.cnName !== row.oldCnName) {
        //修改中文名称
        editMallCategoryApi(row).then(res => {
          this.$message.success(res.msg);
          this.initLoad();
        });
      }
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
</style>